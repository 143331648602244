import React, { useContext } from "react";
import Article from './Article';
import ArticleLarge from './ArticleLarge';
import ArticleSmall from './ArticleSmall';
import {ViewContext, SiteListContext, CategoryContext} from './App';
import { ScrollObserver } from "./ScrollObserver";
import { useCallback, useEffect, useState } from "react";
import Masonry from "react-masonry-css"
import './masonry.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'

function Articles(props) {
  const {siteList, siteListDispatch} = useContext(SiteListContext);
  const {category, categoryDispatch} = useContext(CategoryContext);
  const {view, viewDispatch} = useContext(ViewContext);

  if(!props.data){
    return (
      <React.Fragment>
      </React.Fragment>)
  }

  const breakpointColumnsObj = {
    default: 4,
    1279: 3,
    959: 2,
    599: 1,
  }


  dayjs.extend(relativeTime)
  dayjs.locale('ja');
  let current_date = dayjs();

  let filteredData = props.data.filter(function(content) {
    let date_date = dayjs(content.metadata.date);
    let fromNow = date_date.fromNow();
    let diff_date = current_date.diff(date_date,"minute");
    if(diff_date < 0) return false;
    if(!category.includes(content.metadata.category)) return false;
    return siteList.includes(content.metadata.creator);
  });
  return (
    <React.Fragment>
      {view == "list" &&
        <Masonry breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
          {filteredData.map((_content, index) => (
            <ArticleSmall key={index} content={_content} elevation={2} />
          ))}
        </Masonry>
      }
      {view == "module" &&
        <Masonry breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
          {filteredData.map((_content, index) => (
            <Article key={index} content={_content} elevation={2} />
          ))}
        </Masonry>
      }
      {view == "quilt" &&
        <Masonry breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
          {filteredData.map((_content, index) => (
            <ArticleLarge key={index} content={_content} elevation={2} />
          ))}
        </Masonry>
      }
      <ScrollObserver
        onIntersect={props.dataUpdate}
        isActiveObserver={props.isActiveObserver}
      />
    </React.Fragment>
  )
}

export default Articles
