import React, { useContext, useMemo } from "react";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {SiteListContext} from './App';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {SITE_LIST} from './constants.js';

SiteListDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SiteListDialog(props) {
  const {siteList, siteListDispatch} = useContext(SiteListContext);
  const handleSiteListItems = (event, newView) => {
    let value = event.target.value;
    let checked = event.target.checked;
    let action;
    if (checked === true) {
      action = "SET_" + value;
    }else{
      action = "UNSET_" + value;
    }
    siteListDispatch(action);
  };

  const theme = useTheme();

  const SettingNameTypography = styled(Typography)({
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  });

  return useMemo(() => {
  return (
    <Box sx={{pl:0, pt:1}}>
      <Box>
        <FormGroup>
          {SITE_LIST.map((siteName, index) => (
            <FormControlLabel key={index} control={
              <Checkbox
                checked={siteList.includes(siteName)}
                onChange={handleSiteListItems}
              />
            } value={siteName} label={siteName} />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
}, [theme, siteList, props])
}
