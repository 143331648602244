/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {OptionsContext, ViewContext} from './App';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
//import Divider from '@mui/material/Divider';
import { DisplayContext } from './App';
import { CategoryContext } from './App';
import { NewTabContext } from './App';
import { css } from '@emotion/react';
import SiteListDialog from './SiteListDialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function SimpleSettings(props: Props) {

  const {newTab, newTabDispatch} = useContext(NewTabContext);

  const theme = useTheme();
  const hr_color = theme.palette.mode === 'dark' ? theme.palette.grey["800"] : theme.palette.grey["300"];

  const {view, viewDispatch} = useContext(ViewContext);
  const handleView = (event, newView) => {
    if (newView === 'list') {
      viewDispatch('SET_LIST');
    }
    else if (newView === 'module') {
      viewDispatch('SET_MODULE');
    }
    else if (newView === 'quilt') {
      viewDispatch('SET_QUILT');
    }
  };

  const {display, displayDispatch} = useContext(DisplayContext);
  const handleDisplayItems = (event, newView) => {
    let value = event.target.value;
    let checked = event.target.checked;
    if (value === 'image') {
      if (checked === true) {
        displayDispatch('SET_IMAGE');
      }else{
        displayDispatch('UNSET_IMAGE');
      }
    }
    else if (value === 'title') {
      if (checked === true) {
        displayDispatch('SET_TITLE');
      }else{
        displayDispatch('UNSET_TITLE');
      }
    }
    else if (value === 'description') {
      if (checked === true) {
        displayDispatch('SET_DESCRIPTION');
      }else{
        displayDispatch('UNSET_DESCRIPTION');
      }
    }
    else if (value === 'creator') {
      if (checked === true) {
        displayDispatch('SET_CREATOR');
      }else{
        displayDispatch('UNSET_CREATOR');
      }
    }
    else if (value === 'date') {
      if (checked === true) {
        displayDispatch('SET_DATE');
      }else{
        displayDispatch('UNSET_DATE');
      }
    }
  };

  const {category, categoryDispatch} = useContext(CategoryContext);
  const handleCategoryItems = (event, newView) => {
    let value = event.target.value;
    let checked = event.target.checked;
    if (value === 'niji') {
      if (checked === true) {
        categoryDispatch('SET_NIJI');
      }else{
        categoryDispatch('UNSET_NIJI');
      }
    }
    else if (value === 'holo') {
      if (checked === true) {
        categoryDispatch('SET_HOLO');
      }else{
        categoryDispatch('UNSET_HOLO');
      }
    }
    else if (value === 'vsupo') {
      if (checked === true) {
        categoryDispatch('SET_VSUPO');
      }else{
        categoryDispatch('UNSET_VSUPO');
      }
    }
  };


  const {options, dispatch} = useContext(OptionsContext);

  const SettingNameTypography = styled(Typography)({
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  });

  const pc_marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  const sp_marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
  ];

  return (
    <React.Fragment>
      <Typography variant="h6" component="p" sx={{pb:1}} color={theme.palette.text.primary} gutterBottom>
        設定
      </Typography>
      <div
      css={css`
        border-bottom: 1px solid ${hr_color};
        padding-top: 5px;
        margin-bottom: 15px;
      `}></div>
      <Stack spacing={2} sx={{pt:2}}>
        <Box>
          <SettingNameTypography variant="caption" component="p" gutterBottom>
            表示形式
          </SettingNameTypography>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleView}
            aria-label="text alignment"
          >
            <ToggleButton value="list" aria-label="left aligned">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="module" aria-label="centered">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="quilt" aria-label="right aligned">
              <ViewQuiltIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          <SettingNameTypography variant="caption" component="p" gutterBottom>
            ダークモード
          </SettingNameTypography>
          <ToggleButtonGroup
            value={options.darkmode == "true" ? "darkmode" : "lightmode"}
            exclusive
            onChange={(e,value) => { value == "darkmode" ? dispatch('DARK_MODE_ON') : dispatch('DARK_MODE_OFF');}}
            aria-label="text alignment"
          >
            <ToggleButton value="lightmode" aria-label="right aligned">
              <Brightness7Icon />
            </ToggleButton>
            <ToggleButton value="darkmode" aria-label="left aligned">
              <Brightness4Icon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <SettingNameTypography variant="caption" component="p" gutterBottom>
            表示項目
          </SettingNameTypography>
          <FormGroup>
            <FormControlLabel onChange={handleDisplayItems} control={
              <Checkbox
                checked={display.includes("image")}
                onChange={handleDisplayItems}
              />
            } value="image" label="画像" />
            <FormControlLabel onChange={handleDisplayItems} control={
              <Checkbox
                checked={display.includes("title")}
                onChange={handleDisplayItems}
              />
            } value="title" label="タイトル" />
            <FormControlLabel control={
              <Checkbox
                checked={display.includes("description")}
                onChange={handleDisplayItems}
              />
            } value="description" label="詳細" />
            <FormControlLabel onChange={handleDisplayItems} control={
              <Checkbox
                checked={display.includes("creator")}
                onChange={handleDisplayItems}
              />
            } value="creator" label="配信元" />
            <FormControlLabel onChange={handleDisplayItems} control={
              <Checkbox
                checked={display.includes("date")}
                onChange={handleDisplayItems}
              />
            } value="date" label="日付" />
          </FormGroup>
        </Box>
        <Box>
          <SettingNameTypography variant="caption" component="p" gutterBottom>
            表示カテゴリ
          </SettingNameTypography>
          <FormGroup>
            <FormControlLabel onChange={handleCategoryItems} control={
              <Checkbox
                checked={category.includes("niji")}
                onChange={handleCategoryItems}
              />
            } value="niji" label="にじさんじ(橙色)" />
            <FormControlLabel onChange={handleCategoryItems} control={
              <Checkbox
                checked={category.includes("holo")}
                onChange={handleCategoryItems}
              />
            } value="holo" label="ホロライブ(水色)" />
            <FormControlLabel onChange={handleCategoryItems} control={
              <Checkbox
                checked={category.includes("vsupo")}
                onChange={handleCategoryItems}
              />
            } value="vsupo" label="ぶいすぽっ！(桃色)" />
          </FormGroup>
        </Box>
        <div
        css={css`
          border-bottom: 1px solid ${hr_color};
          padding-top: 5px;
          margin-bottom: 15px;
        `}></div>
        <Box>
          <SettingNameTypography variant="caption" component="p" gutterBottom>
            ページ遷移
          </SettingNameTypography>
          <RadioGroup
            value={newTab}
            onChange={(e) => { e.target.value == "newTab" ? newTabDispatch('NEW_TAB') : newTabDispatch('SAME_TAB');}}
            aria-label="text alignment"
            name="radio-buttons-group"
          >
            <FormControlLabel value="newTab" control={<Radio />} label="別のタブで開く" />
            <FormControlLabel value="sameTab" control={<Radio />} label="同じタブで開く" />
          </RadioGroup>
        </Box>
        <div
        css={css`
          border-bottom: 1px solid ${hr_color};
          padding-top: 5px;
          margin-bottom: 15px;
        `}></div>
        <Box>
          <SettingNameTypography variant="caption" component="p" gutterBottom>
            表示サイト
          </SettingNameTypography>
          <SiteListDialog/>
        </Box>
      </Stack>
    </React.Fragment>
  );
};
